import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import oko from "../assets/img/new/eye.svg"
import oko_bg from "../assets/img/new/oko_bg.svg"
import target from "../assets/img/new/target.svg"
import target_bg from "../assets/img/new/target_bg.svg"
import money from "../assets/img/new/money.svg"
import money_bg from "../assets/img/new/money_bg.svg"

import trafic from "../assets/img/new/dedykowany-opiekun.svg"
import trafic_bg from "../assets/img/ikonki/strony/dedykowany-opiekun-znak.webp"
import chart from "../assets/img/new/monitoring-fraz.svg"
import chart_bg from "../assets/img/ikonki/strony/monitoring-fraz-znak.webp"
import report from "../assets/img/new/raport.svg"
import report_bg from "../assets/img/ikonki/strony/raport-znak.webp"

import content from "../assets/img/old/grafika-8.webp"
import optimize from "../assets/img/old/grafika-10.webp"
import optimize2 from "../assets/img/old/grafika-9.webp"
import link_building from "../assets/img/old/grafika-14.webp"
import stats from "../assets/img/old/grafika-12.webp"

import footer_bg from "../assets/img/new/tlo-konsultacje-03-4.webp"



import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const WebsitePositioning = () => {
  
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title:
          "Pozycjonowanie stron WWW • Zleć optymalizację swojej witryny WeNet",
        description:
          "Skorzystaj z usługi pozycjonowania strony WWW • Jesteśmy specjalistami w pozycjonowaniu stron - zrobimy to za Ciebie!",
        hreflangs: {
          pl: "pozycjonowanie-stron/",
          en: "en/website-positioning/",
          de: "de/positionierung-von-webseiten/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/pozycjonowanie-stron/",
                name: t("seo-title-website-positioning"),
              },
            },
          ],
        },
      }}
    >
      <div
        className="hero-header-page"
        style={{ backgroundImage: `url("${HeaderImage}")` }}
      >
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-positioning"),
                text: t("link-text-positioning"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Pozycjonowanie stron</h1>
          <p style={{ marginBottom: "40px" }}>
            Nie wiesz, od czego zacząć skomplikowany proces optymalizacji?
            <br />
            Możemy Ci w tym pomóc! Zleć sprawy Twojej strony WWW w nasze ręce.
            <br />
            Jesteśmy specjalistami w pozycjonowaniu stron – zrobimy to za
            Ciebie.
          </p>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>

      <section className="standard-section">
        <div className="container text-center">
          <h2 style={{ fontSize: "36px" }}>
            Jakie są <strong>zalety pozycjonowania</strong> stron?
          </h2>
        </div>
      </section>

      <section className="section-intro no-padding-top no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={oko}
                    alt="Lepsza widoczność serwisu"
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                </div>
                <h3>Lepsza widoczność serwisu</h3>
                <p>
                  Zyskujesz wyższą pozycję w wyszukiwarce i przewagę nad
                  konkurencją.
                </p>
                <img src={oko_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={target}
                    alt="Dostęp do większej liczby użytkowników"
                  />
                </div>
                <h3>Dostęp do większej liczby użytkowników</h3>
                <p>
                  Więcej klientów odwiedza Twoją stronę, a Ty budujesz zaufanie.
                </p>
                <img src={target_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={money} alt="Gotowe wskazówki" />
                </div>
                <h3>Zysk dla firmy i oszczędności</h3>
                <p>
                  Inwestycja w pozycjonowanie się opłaca i możesz liczyć na
                  długotrwałe rezultaty.
                </p>
                <img src={money_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="standard-section">
        <div className="container">
          <h2 className="text-center" style={{ fontSize: "36px" }}>
            <strong>Na czym polegają</strong> nasze usługi pozycjonowania?
          </h2>
        </div>
      </section>

      <section className="text-image image-right">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Optymalizacja treści</strong>
            </h2>
            <ul>
              <li>
                przeprowadzamy szczegółową analizę tekstów z uwzględnieniem fraz
                kluczowych,
              </li>
              <li>
                optymalizujemy treści, Meta Dane, nagłówki H1, H2, H3 oraz
                linkowanie wewnętrzne,{" "}
              </li>
              <li>sprawdzamy serwis pod względem kopii treści, </li>
              <li>eliminujemy kanibalizację treści podstron witryny, </li>
              <li>analizujemy linkowanie wewnętrze strony.</li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${content}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left reverse-flex">
        <div
          className="half"
          style={{
            background: `url(${optimize}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Optymalizacja techniczna</strong>
            </h2>
            <ul>
              <li>dbamy o poprawną strukturę strony, </li>
              <li>pozbywamy się technik nieprzyjaznych SEO, </li>
              <li>
                sprawdzamy adresy URL, aby były przyjazne dla wyszukiwarek,
              </li>
              <li>
                przeprowadzamy test prędkości strony oraz sprawdzamy jej
                responsywność,
              </li>
              <li>
                poprawiamy obecne przekierowania lub dodajemy nowe przyjazne
                wyszukiwarce.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Optymalizacja techniczna c.d.</strong>
            </h2>
            <ul>
              <li>
                dbamy o grafiki, by miały odpowiednie atrybuty i szybciej się
                ładowały,
              </li>
              <li>
                implementujemy na stronie znaczniki strukturalne zgodne ze
                schema.org,
              </li>
              <li>dostosowujemy stopkę serwisu do standardów SEO,</li>
              <li>
                dodajemy na stronę iframe z lokalizacją firmy w Google Maps,
              </li>
              <li>
                poprawiamy inne techniczne błędy na stronie, które utrudniają
                lub uniemożliwiają indeksację strony lub jej części.
              </li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${optimize2}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left reverse-flex">
        <div
          className="half"
          style={{
            background: `url(${link_building}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Link Building i Content Marketing</strong>
            </h2>
            <ul>
              <li>
                piszemy i publikujemy artykuły content marketingowe na
                wartościowych stronach,
              </li>
              <li>
                zakładamy wizytówki w wartościowych katalogach firm oraz
                serwisach branżowych linkujące do strony klienta,
              </li>
              <li>
                zakładamy lub aktualizujemy Google Moja Firma i dodajemy wpisy,
              </li>
              <li>
                budujemy wizerunek firmy w sieci poprzez tworzenie wpisów,
              </li>
              <li>wpisujemy firmę do katalogów SEO,</li>
              <li>zakładamy, konfigurujemy, prowadzimy bloga,</li>
              <li>
                zakładamy fanpage i prowadzimy komunikację w Social Mediach,
              </li>
              <li>instalujemy wtyczki do Twoich kont na Social Mediach.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Statystyki</strong>
            </h2>
            <ul>
              <li>dodajemy serwis do Google Search Console,</li>
              <li>dodajemy serwis do Google Analytics,</li>
              <li>analizujemy statystyki serwisu,</li>
              <li>sprawdzamy pozycję fraz kluczowych,</li>
              <li>sprawdzamy widoczność strony w Google.</li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${stats}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="section-intro">
        <div className="container">
          <div className="row">
            <div className="row-md-12">
              <h2 style={{ fontSize: "36px" }}>
                <strong>Zapewniamy:</strong>
              </h2>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={trafic} alt="Lepsza widoczność serwisu" />
                </div>
                <h3>Dedykowanego opiekuna</h3>
                <ul>
                  <li>będzie monitorował wyniki Twojej witryny,</li>
                  <li>
                    codziennie będzie do Twojej dyspozycji i udzieli wyjaśnień.
                  </li>
                </ul>
                <img src={trafic_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={chart}
                    alt="Monitoring fraz i monitoring widoczności"
                  />
                </div>
                <h3>Monitoring fraz i monitoring widoczności</h3>
                <ul>
                  <li>masz dostęp do szczegółowych danych,</li>
                  <li>możesz śledzić efekty naszej pracy.</li>
                </ul>
                <img src={chart_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={report} alt="Comiesięczny raport" />
                </div>
                <h3>Comiesięczny raport</h3>
                <ul>
                  <li>
                    znajdziesz w nim najważniejsze dane dotyczące postępów
                    naszych działań,
                  </li>
                  <li>masz pełną kontrolę nad całym procesem.</li>
                </ul>
                <img src={report_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="outro"
        style={{
          backgroundImage: `url(${footer_bg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                Skorzystaj z naszej oferty SEO i zleć optymalizację specjalistom
                <span style={{ display: "inline-block" }}>z WeNet</span> –
                wzmocnij pozycję Twojej strony WWW!
              </h3>

              <Link to={t("link-url-contact")} className="btn btn-primary">
                Chcę darmową wycenę optymalizacji
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WebsitePositioning
